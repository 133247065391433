<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading" v-if="!apierror">
            <van-nav-bar :title="$t('cashier.cashier')" left-arrow @click-left="returnpev" />
            <div class="orderinfos">
              <p>{{ $t("cashier.total") }}</p>
              <h1>{{ $t('currey') }}{{ order.total }}</h1>
            </div>
            <div class="peyment">
              <van-radio-group v-model="radio">
                <van-cell-group inset>
                  <van-cell v-for="(val, idx) in filteredPayway" :title="getTranslatedPayway(val)" :key="idx"
                    size="large" clickable @click="radio = val">
                    <template #right-icon>
                      <van-radio :name="val" />
                    </template>
                  </van-cell>
                </van-cell-group>
              </van-radio-group>
            </div>
            <van-submit-bar v-if="filteredPayway.length > 0" :loading="paymentloading" :price="order.total * 100"
              :label="$t('detail.totalprice')" :currency="$t('currey')" :button-text="$t('cashier.payment')"
              @submit="payment" />
            <van-submit-bar v-else :loading="paymentloading" :price="order.total * 100" :label="$t('detail.totalprice')"
              :button-text="$t('cashier.payment')" :currency="$t('currey')" :tip="$t('cashier.nopayments')" disabled />
          </div>

          <div v-else>
            <van-empty v-if="apierror" image="error" :description="$t('global.deviceapierror')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Starter page component
 */
export default {
  data() {
    return {
      loading: true,
      paymentloading: false,
      payway: [],
      apierror: false,
      order: {
        total: 0.0,
      },
      radio: "",
    };
  },
  mounted() {
    this.getpaymentstatus();
  },
  computed: {
    filteredPayway() {
      // 根据条件筛选支付方式
      return this.order.total > 8
        ? this.payway
        : this.payway.filter((method) => method !== "stripe");
    }
  },
  methods: {
    getpaymentstatus() {
      var that = this;
      that.$axios
        .post(that.apiuri, {
          action: "checkpaymentstatus",
          oid: that.$route.params.id,
        })
        .then(function (response) {
          that.loading = false;
          if (response.data.status == 200) {
            that.$router.push({
              name: "success",
              params: { id: that.$route.params.id },
            });
          } else {
            that.payway = response.data.payway;
            that.order = response.data.data;
            if (that.order.default_pay == 2) that.radio = "alipay";
            if (that.order.default_pay == 3) that.radio = "wxpay";
            var ua = window.navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
              that.radio = "wxpay";
            }
          }
        })
        .catch(function (error) {
          that.apierror = true;
          console.log(error);
          that.loading = false;
        });
    },
    payment() {
      var that = this;
      that.paymentloading = true;
      that.$axios
        .post(that.apiuri, {
          action: "makepayment",
          ordersn: that.$route.params.id,
          payway: that.radio,
        })
        .then(function (response) {
          that.paymentloading = false;
          if (response.data.status == 200) {
            that.loading = true;
            let formData = new FormData();
            formData.append('ordersn', that.$route.params.id);
            formData.append('type', 'wap');

            that.$axios.post('https://apiv3.aicopay.com/pay', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
              .then(function (response) {
                that.loading = false;
                if (response.data.status == 200) {
                  that.loading = true;
                  if (response.data.qr_code) response.data.url = response.data.qr_code
                  if (response.data.url && response.data.url.length > 10) {
                    window.location.href = response.data.url;
                  } else {
                    that.$dialog.alert({ message: "支付服务通信错误，请稍后再试" });
                  }
                } else {
                  if (response.data.msg) {
                    that.$dialog.alert({ message: response.data.msg });
                  } else {
                    that.$dialog.alert({ message: "支付服务通信错误，请稍后再试" });
                  }
                }
              })
              .catch(function (error) {
                // 处理错误情况
                console.error('请求失败:', error);
                that.$dialog.alert({ message: "网络错误，请稍后再试" });
                that.loading = false;
              });

          } else {
            that.$dialog.alert({ message: "服务端异常，请稍后再试" });
          }
        });
    },
    returnpev() {
      this.$router.go(-1);
    },
    getTranslatedPayway(paycode) {
      switch (paycode) {
        case "alipay":
          return this.$t("payway.alipay");
        case "wxpay":
          return this.$t("payway.wechatpay");
        case "stripe":
          return "Stripe";
        case "bestpay":
          return this.$t("payway.bestpay");
        case "linepay":
          return "Line";
        case "qpay":
          return "QQ支付";
        case "wxpaymico":
          return "微信小程序支付";
      }
    },
  },
};
</script>
<style>
.card-body {
  padding: 0;
}

.van-nav-bar .van-icon {
  color: #000;
}

.bottom_logo {
  bottom: 3rem;
}

.orderinfos {
  text-align: center;
  padding-top: 5rem;
}
</style>
